// Main Modal Window
.custom-modal {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

// heading and exit button comtainer
.exit-container {
    display: flex;
    height: 20px;
    width: 100%;
    justify-content: flex-end;
    padding-right: 30px;
    padding-top: 25px;
    @media only screen and (max-width: 450px) {
        padding-top: 10px;
        padding-right: 5px;
    }
}

// Content/Input Field Container
.custom-modal-content {
    background-color: #0C2C5C;
    align-items: center;
    width: 30vw;
    height: 65vh;
    justify-content: center;
    border-radius: 20px;
    @media only screen and (max-width: 450px) {
        width: 300px;
        height: 63vh;
    }
}

// New Client Title Container
.custom-modal-header {
    justify-content: center;
    display: flex;
    margin-top: 35px;
    @media only screen and (max-width: 450px) {
        margin-top: 10px;
    }
}

// Form Container
.custom-modal-body {
   padding-left: 40px;
   padding-right: 40px;
   @media only screen and (max-width: 450px) {
    padding-left: 20px;
    padding-right: 20px;
}
}

// Form Sub-titles
.custom-modal-body h3 {
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    padding-top: 10px;
    color: white;
    @media only screen and (max-width: 450px) {
        font-size: 12px;
        padding-top: 5px;
    }
}

// Form Input Fields
.modal-form-input {
    border: none;
    background-color: white;
    color: black;
    border-radius: 3px;
    @media only screen and (max-width: 450px) {
        height: 25px;
        font-size: 10px;
    }
}



// Form drop-down menu
.modal-form-dropdown select {
    background-color: white;
    margin-top: 10px;
    height: 30px;
    font-size: 15px;
    font-family: 'Montserrat-Light';
    border: none;
    border-radius: 3px;
    padding: 3px;
    @media only screen and (max-width: 450px) {
        height: 20px;
        font-size: 10px;
    }
}

.select {
    @media only screen and (max-width: 450px) {
        height: 20px;
        font-size: 10px;
    }
}

.options {
    @media only screen and (max-width: 450px) {
        height: 25px;
        font-size: 10px;
    }
}

.css-dbhxaa-MuiFormControl-root-MuiTextField-root input {
    @media only screen and (max-width: 450px) {
        color: black;
        padding: 4px;
        height: 25px;
        font-size: 10px;
    }
}
// First and Last name container
.name-container {
    display: flex; 
}

// First Name
.first-name-container {
    width: 50%;
}

// Last Name
.last-name-container {
    margin-left: 15px;
    width: 50%;
}

// Submit Button Container
.custom-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}

.error-message {
    color:#E12C4E;
    font-family: 'Montserrat-Bold';
    font-size: 12px;
}

#submit-button {
    text-align: center;
    font-family: 'Montserrat-Bold';
    font-size: 2rem;
    width: 200px;
    height: 67px;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: #E12C4E;
    cursor: pointer;
    @media only screen and (max-width: 450px) {
        font-size: 1rem;
        width: 100px;
        height: 30px;
    }
}

#modal-title {
    color: white;
    font-size: 40px;
    font-family: 'Montserrat-Bold';
    @media only screen and (max-width: 450px) {
        font-size: 25px;
    }
}

#close-button-container {
    background-color: #0C2C5C;
    color: #E12C4E;
    border-width: 0;
    font-size: 40px;
    margin-top: 10px;
    cursor: pointer;
    @media only screen and (max-width: 450px) {
        font-size: 20px;
    }
}