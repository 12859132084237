#side-bar-container {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100vh;
        background: #0C2C5C;
        display: grid;
        align-items: center;
        top: 0;
        left: 0;
        transition: 1s ease-in-out;
}

.icon {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
}

#close {
    color: white;
}

.side-bar-wrapper {
    color: #fff;
}

.side-bar-menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 200px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(2, 80px);
    }
}

.side-bar-link {
    display: flex;
    align-items: top;
    justify-content: center;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-family: 'Montserrat-Bold';
    font-size: 2rem;
    font-weight: 450;
    &:hover {
        color: #E12C4E;
        transition: 0.2s ease-in-out;
    }
}