// Main Container for the page
.profile-container {
    padding: 5rem;
    @media only screen and (max-width: 450px) {
        padding: 1rem;
        margin-top: 1.5rem;;
    }
}

// Container for the heading and the create new opportunity button
.heading-container {
    display: flex;
    justify-content: space-between;
}

// divider
hr.solid {
    border-top: 3px solid #0C2C5C;
    margin-top: 1rem;
}

// Main Heading container
.profileHeading {
    display: block;
}

// The title
.profileHeading h1{
    font-family: 'Montserrat-Bold';
    color: #0C2C5C;
    font-size: 2.75rem;
    @media only screen and (max-width: 450px) {
        font-size: 1.2rem;
        margin-top: 10px;
    }
}

// Create button styling
.create-button {
    text-align: center;
    font-family: 'Montserrat-Bold';
    font-size: 1.25rem;
    color: white;
    border: none;
    border-radius: 5px;;
    background-color: #0C2C5C;
    width: 250px;
    height: 80px;
    cursor: pointer;
    @media only screen and (max-width: 450px) {
        font-size: 0.65rem;
        width: 130px;
        height: 40px;
    }
}

// The Tile container
.tableBox {
    display: block;
    width: 100%;
    padding-top: 40px;
    @media only screen and (max-width: 450px) {
        padding-top: 20px;;
    }
}

