* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

@font-face{
  font-family: "Montserrat-Bold";
  src: url("./Fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: "Montserrat-Light";
  src: url("./Fonts/Montserrat/static/Montserrat-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: "Roboto-Slab-Light";
  src: url("./Fonts/Roboto_Slab/static/RobotoSlab-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: light;
}