// Main Navagation Container
.custom-navbar {
    background: #0C2C5C;
    height: 152px;
    display: flex;
    justify-content: left;
    align-items: center;
    z-index: 10;
    padding-left: 5rem;
    padding-right: 5rem;
    @media only screen and (max-width: 450px) {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 130px;
    }
}

.menu-container {
    display: none;
    @media only screen and (max-width: 450px) {
        display: flex; 
        width: 50px;
        height: 50px;
        justify-content: center;
        margin-right: 10px;
        margin-bottom: 10px;;
    }
}

#hamburger {
    color: white;
    width: 50px;
    height: 50px
}

// Heading Container
.nav-heading-container {
    display: flex;
    justify-content: flex-start;
}

.link {
    text-decoration: none;
}
// Title 
#nav-heading {
    color: white;
    font-family: 'Roboto-Slab-Light';
    font-size: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    // width: 50vw;

    @media only screen and (max-width: 450px) {
        font-size: 2rem;
        padding: 0rem;
    }
}

.nav-button {
    @media only screen and (max-width: 450px) {
        display: none;
    }
}

.nav-profile-container {
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
    margin-top: 10px;
    width: 100%;
    height: 75%;
}

// Logo and profile container
.nav-logo-container {
    display: flex;
    flex-grow: 2;
    padding-top: 20px
}

.button-container {
    display: flex;
    flex-direction: column;
}

.nav-button-container {
    display: flex;
    flex-direction: row;
    height: 33%;
}

.navigation-button {
    text-align: center;
    font-family: 'Montserrat-Bold';
    font-size: 1.25rem;
    color: white;
    border: none;
    border-radius: 5px;;
    background-color: transparent;
    // width: 275px;
    width: auto;
    white-space: nowrap;
    margin: 5px 15px 5px 15px ;
    height: auto;
    cursor: pointer;

    &:hover {
        color: #E12C4E;
        transition: 0.2s ease-in-out;
    }

    @media only screen and (max-width: 450px) {
        font-size: 0.65rem;
        width: 130px;
        height: 40px;
    }
}

.auth-button-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0;
    top: auto;
    bottom: 0;
}

.current-user {
    text-align: end;
    font-family: 'Montserrat-Bold';
    font-size: 0.8rem;
    color: white;
    border: none;
    border-radius: 5px;;
    background-color: transparent;
    // width: 275px;
    width: auto;
    white-space: nowrap;
    margin: 5px 15px 5px 15px ;
    height: auto;

    // cursor: pointer;
    // &:hover {
    //     color: #E12C4E;
    //     transition: 0.2s ease-in-out;
    // }

    @media only screen and (max-width: 450px) {
        font-size: 0.65rem;
        width: 130px;
        height: 40px;
    }
}

// Integral Logo
#logo {
    width: 91px;
    height: 86px;
    @media only screen and (max-width: 450px) {
        display: none;
        width: 50px;
        height: 45px;
    }
}

// Link to profile page
#profile-button {
    display: flex;
    font-size: 4rem;
    color: white;
    text-decoration: none;
    @media only screen and (max-width: 450px) {
        font-size: 2.5rem;
    }
}
