// Main Window Container
.form-container {
    padding: 5rem;
    padding-top: 4rem;
    @media only screen and (max-width: 450px) {
        padding: 1rem;
    }
}

// Heading Container
.form-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

// Main Title
.form-header h1 {
    font-size: 2.75rem;
    font-family: 'Montserrat-Bold';
    color: #0C2C5C;
    margin-top: 15px;
    @media only screen and (max-width: 450px) {
        font-size: 1.5rem;
    }
    @media only screen and (max-width: 365px) {
        font-size: 1.3rem;
    }
}

.sub-heading-container {
    border-top: 2.5px solid #0C2C5C;
    margin-top: 10px;

    @media only screen and (max-width: 450px) {
        margin-top: 5px;
    }
}

.sub-heading-divider {
    opacity: 100%;
    border-top: 1px solid #0C2C5C;

    @media only screen and (max-width: 450px) {
        margin-top: 5px;
        border-top: 1px solid #0C2C5C;
    }
    
}

// Question Title and Astrix Container
.question-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    @media only screen and (max-width: 450px) {
        margin-top: 10px;
    }
}

// Section Titles Container
.section-titles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    @media only screen and (max-width: 450px) {
        margin-top: 0px;
    }
}

.astrix {
    font-size: 1rem;
    color: #E12C4E;
}


// Form Titles
.form-content h1 {
    font-size: 30px;
    font-family: 'Montserrat-Bold';
    color: #0C2C5C;
    margin-top: 20px;
    @media only screen and (max-width: 450px) {
        font-size: 20px;
        margin-top: 10px;
    }
    @media only screen and (max-width: 365px) {
        font-size: 18px;
    }
}

.source-container {
    margin-top: 20px;
}

.project-container {
    margin-top: 20px;
}

// Form Sub-Titles
.form-content h3 {
    font-size: 20px;
    font-family: 'Montserrat-Bold';
    color: #0C2C5C;
    margin-top: 8px;
    @media only screen and (max-width: 450px) {
        font-size: 15px;
    }
    @media only screen and (max-width: 365px) {
        font-size: 15px;
        margin-top: 2px;
    }
}

.create-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.client-input {
    height: 50px;
}

.rbt-input-multi .rbt-input-main {
    height: 40px;
}

.rbt-token {
    margin: 10px 3px 2px 0;
}

 .form-control {
    margin-top: 4px;
    height: 50px;

    @media only screen and (max-width: 450px) {
        font-size: 15px;
    }
}

#create-button {
    text-align: center;
    font-family: 'Montserrat-Bold';
    font-size: 1.6rem;
    color: white;
    border: none;
    border-radius: 5px;;
    background-color: #E12C4E;
    width: 230px;
    height: 50px;
    cursor: pointer;
    @media only screen and (max-width: 450px) {
        width: 100%;
        height: 35px;
        font-size: 0.8rem;
    }
}

#new-contact {
    text-align: left;
    font-family: 'Montserrat-Bold';
    font-size: 1rem;
    color: white;
    border: none;
    border-radius: 5px;
    background-color:#E12C4E;
    height: 30px;
    width: 250px;
    cursor: pointer;
    margin-top: 13px;
    margin-left: 5px;
    @media only screen and (max-width: 450px) {
        font-size: 0.7rem;
        height: 30px;
        width: 175px;
    }
}

#client-contact {
   padding-top: 10px;
}

// Divider
#heading-divider {
    border-top: 3px solid #0C2C5C;
    margin-top: 1rem;
    opacity: 100%;
    @media only screen and (max-width: 450px) {
        border-top: 2px solid #0C2C5C;
        margin-top: 5px;
    }
}

#bottom-divider {
    border-top: 3px solid #0C2C5C;
    margin-top: 30px;
    opacity: 100%;
}

#project-title {
    bottom: -10px
}
