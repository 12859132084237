// Page container
.error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

// Heading Container
.error-heading-container {
    display: block;
    justify-content: center;
    text-align: center;
}

// Title
.error-heading-container h1 {
    font-family: 'Montserrat-Bold';
    color: #0C2C5C;
    font-size: 2rem;
    
}

// Link back to opportunity container
.error-link-container {
    display: block;
    justify-content: center;
    text-align: center;
}

// Link
.error-link-container h2 {
    font-family: 'Montserrat-Bold';
    color: #E12C4E;
    font-size: 1rem;

}